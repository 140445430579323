.dark-theme .rdrDefinedRangesWrapper {
    background-color: transparent !important;
    border-right: 2.5px dotted #37424d;
}

.dark-theme .rdrStaticRange {
    background-color: transparent !important;
    border-bottom: 2.5px dotted #37424d;
}

.dark-theme .rdrStaticRange:hover {
    background-color: transparent !important;
}

.dark-theme .rdrStaticRanges:hover {
    background-color: transparent !important;
}

.dark-theme .rdrStaticRangeLabel {
    color: white !important;
}

.dark-theme .rdrDayPassive span {
    color: #454F5B !important;
}

.dark-theme .rdrDateDisplayWrapper {
    background-color: #212b36 !important;
}

.dark-theme .rdrCalendarWrapper {
    background-color: transparent !important;
}

.dark-theme .rdrDayDisabled {
    background-color: #b7b7b7 !important;
    opacity: .3;
}

.dark-theme .rdrDayNumber span {
    color: white;
}

.dark-theme .rdrMonthAndYearPickers select {
    color: white;
    background-color: #212b36 !important;
}

.dark-theme .rdrStaticRange:hover .rdrStaticRangeLabel {
    background-color: rgba(145, 158, 171, 0.08);
}

.dark-theme .rdrStaticRangeSelected {
    background-color: rgba(145, 158, 171, 0.08) !important;
}

.dark-theme .rdrInputRanges {
    display: none;
}

.dark-theme .rdrDateDisplayItem {
    background-color: transparent;
    border: 1.5px solid #2e363e;
    border-radius: 8px;
}

.dark-theme .rdrNextPrevButton {
    background-color: transparent;
    border: 1px solid grey;
}

.dark-theme .rdrPprevButton i {
    border-width: 4px 6px 4px 4px;
    border-color: transparent grey transparent transparent;
    transform: translate(-3px, 0px);
}

.dark-theme .rdrNextButton i {
    margin: 0 0 0 7px;
    border-width: 4px 4px 4px 6px;
    border-color: transparent transparent transparent grey;
    transform: translate(3px, 0px);
}

.dark-theme .rdrNextPrevButton:hover {
    background-color: transparent;
}

.dark-theme .rdrDateDisplayItemActive {
    border-color: currentColor
}

/* LIGHT MODE */

.light-theme .rdrDefinedRangesWrapper {
    background-color: transparent !important;
    border-right: dotted 2.5px #eff2f7
}

.light-theme .rdrStaticRange {
    background-color: transparent !important;
    border-bottom: dotted 2.5px #eff2f7
}

.light-theme .rdrStaticRange:hover {
    background-color: rgba(0, 0, 0, 0.07) !important;
}

.light-theme .rdrStaticRangeSelected {
    background-color: rgba(0, 0, 0, 0.07) !important;
}


.light-theme .rdrStaticRanges:hover {
    background-color: transparent !important;
}

.light-theme .rdrStaticRangeLabel {
    color: black !important;
}

.light-theme .rdrDateDisplayWrapper {
    background-color: white !important;
}

.light-theme .rdrCalendarWrapper {
    background-color: transparent !important;
}

.light-theme .rdrDayNumber span {
    color: black;
}

.light-theme .rdrMonthAndYearPickers select {
    color: black;
}

.light-theme .rdrStaticRange:hover .rdrStaticRangeLabel {
    background-color: transparent;
}

.light-theme .rdrInputRanges {
    display: none;
}

.light-theme .rdrDayPassive span {
    color: #e8ecee !important;
}

@media (max-width: 600px) {
    .rdrDefinedRangesWrapper {
        display: none;
    }

    .rdrDateRangePickerWrapper {
        width: 100% !important;
    }

    .rdrCalendarWrapper {
        width: 100%;
    }

    .rdrMonth {
        width: 100%;
    }
}

a:focus,
a:hover {
    text-decoration: none !important;
}

.dark-theme .rs-picker-popup {
    background-color: #202C39 !important;

    .rs-picker-select-menu {
        background-color: #202C39 !important;
        color: #fff !important;
    }

    ;

    .rs-picker-select-menu-item {
        color: #fff !important;

        /* :hover {
            background-color: blue;
        } */
        .rs-picker-list-item {
            border: 'solid 1px red'

        }
    }

    .rs-picker-select-menu-item-active {
        background-color: #2B3542 !important;
    }

    .rs-picker-select-menu-item-focus {
        background-color: initial;
    }

    .rs-picker-list-item {
        :hover {
            background-color: #2A3039;
        }
    }
}

.light-theme .rs-picker-popup {
    background-color: #fff !important;

    .rs-picker-select-menu {
        background-color: #fff !important;
        /* color: #000 */
    }

    ;

    .rs-picker-select-menu-item {
        color: #000 !important;

        .rs-picker-list-item {
            border: 'solid 1px red'

        }
    }

    .rs-picker-select-menu-item-active {
        background-color: #DCE0E4 !important;
        /* color: #2B3542 */
    }
}


.am5-layer-30 {
    display: none !important
}

.custom-tooltip {
    background-color: rgba(0, 0, 0, 0.5) !important;
    color: #ffffff !important;
    padding: 10px !important;
    border-radius: 10px !important;
    font-size: 12px !important;
}

.apexcharts-datalabels-group {
    display: none !important;
}

.css-1o415lm {
    padding-bottom: 30px !important;
}

.css-n7tliy-MuiStep-root {
    padding-left: 0px !important;
    padding-right: 0px !important;
}

.css-hk3suq-MuiFormHelperText-root {
    margin-left: 0px !important;
}

li.hovered .organic-searches {
    display: none !important;
}

li.hovered .hover-only-button {
    display: block !important;
}

.css-kbkz4z #notistack-snackbar {
    width: 370px !important
}

.css-138xg6t {
    width: 70px !important
}


#SvgjsRect9400 {
    width: 100% !important;
}

.icon-navbar {
    color: red !important;
    fill: red !important;
    stroke: red !important;
}

.css-af2if-MuiRating-root .MuiSvgIcon-root {
    width: 15px !important;
    height: 15px !important;
    position: relative;
    top: 2.5px !important;
}

.css-1mjkg0h-MuiStack-root {
    padding: 0px !important
}

.css-1mjkg0h-MuiStack-root {
    background-color: transparent !important;
}

.css-1sx8kt7,
.css-xh3tol {
    background-color: transparent !important;
}

.css-1rij8rd-MuiStack-root {
    background-color: transparent !important;
}

.css-q3h9fd-MuiStack-root,
.css-1lrcgwq-MuiStack-root,
.css-1tu7j47-MuiStack-root,
.css-1s98192-MuiStack-root {
    background-color: transparent !important;
}

.css-q3h9fd-MuiStack-root,
.css-1lrcgwq-MuiStack-root,
.css-1s98192-MuiStack-root,
.css-gusybe-MuiStack-root {
    padding-bottom: 15px !important;
}

.rs-picker-has-value.rs-picker-cleanable .rs-picker-toggle.rs-btn,
.rs-picker-toggle-wrapper .rs-picker-toggle.rs-btn {
    background-color: #242c37 !important;
}

.css-gusybe-MuiStack-root {
    padding-top: 7px !important;
    padding-bottom: 7px !important;
}

.css-1hyqeup-MuiDataGrid-toolbarContainer,
.css-1u1c8rd-MuiStack-root {
    background-color: transparent !important;
}

.css-4iz8uw-MuiStack-root,
.css-15lrfpi-MuiStack-root {
    padding-top: 30px !important;
    padding-bottom: 20px !important;
    background-color: transparent !important;
}

.css-1u1c8rd-MuiStack-root {
    padding-top: 31px !important;
    padding-bottom: 21px !important;
}

.css-9493y6 {
    padding-bottom: 25px !important
}

@keyframes pulse-glow-search-term {

    0%,
    100% {
        transform: scale(1);
        box-shadow: 0 0 0 0 rgba(97, 243, 243, 0.7);
    }

    50% {
        box-shadow: 0 0 0 10px rgba(52, 152, 219, 0);
        background-color: #00B8D9;
    }
}

.pulse-glow-search-term {
    animation: pulse-glow-search-term 2s infinite;
}

@keyframes pulse-glow-adgroup {

    0%,
    100% {
        transform: scale(1);
        box-shadow: 0 0 0 0 rgba(119, 237, 139, 0.7);
    }

    50% {
        box-shadow: 0 0 0 10px rgba(52, 152, 219, 0);
        background-color: #22C55E;
    }
}

.pulse-glow-ad-group {
    animation: pulse-glow-adgroup 2s infinite;
}

@keyframes pulse-glow-keyword {

    0%,
    100% {
        transform: scale(1);
        box-shadow: 0 0 0 0 rgba(255, 214, 102, 0.7);
    }

    50% {
        box-shadow: 0 0 0 10px rgba(52, 152, 219, 0);
        background-color: #FFAB00;
    }
}

.pulse-glow-keyword {
    animation: pulse-glow-keyword 2s infinite;
}

@keyframes pulse-glow-campaign {

    0%,
    100% {
        transform: scale(1);
        box-shadow: 0 0 0 0 rgba(255, 172, 130, 0.7);
    }

    50% {
        box-shadow: 0 0 0 10px rgba(52, 152, 219, 0);
        background-color: #FF5630;
    }
}

.pulse-glow-campaign {
    animation: pulse-glow-campaign 2s infinite;
}

#notistack-snackbar {
    z-index: 9999 !important;
}

.css-16bvez {
    -webkit-flex: 0 0 15% !important;
}

.Mui-disabled {
    display: none!important;
}

.css-usn8iv{
    -webkit-flex: 0 0 30%!important;
}

.css-18rhfz4-MuiStack-root {
    padding: 5px 5px!important;
}